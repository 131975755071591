<template>
  <div class="container-fluid">
    <div class="row justify-content-center " style='height : 100vh  '>
      <div class="col-12 align-self-center ">
        <div class=''> 
          <div class="text-center">
            <div class="text-404 my-5">
              404
            </div>
            <div class="">
              <button type='button' class="btn-pri col-6 col-md-4 text-center" @click='$router.push({name: "Home"})'>
                go back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>